<script>
import { computed, onMounted, reactive } from "vue";
import axios from "axios";
import jsSHA from "jssha";
import Clock from "@/components/Clock.vue";
export default {
  components: {
    Clock,
  },
  setup() {
    // 所有到站時間 array
    const mrtDatas = reactive({ data: [] });

    // 動態更改所有站別的 inline width
    const floatWrapWidth = computed(() => {
      const width = mrtDatas.data.length * (260 + 8);
      return `${width}px`;
    });

    // 取得 data 的 header function
    function GetAuthorizationHeader() {
      var AppID = "7f1da0d7442741a29d95cbda7f4d45d9";
      var AppKey = "smYjjQ6OFyU5tgzV5py5SiM7HWw";

      var GMTString = new Date().toGMTString();
      var ShaObj = new jsSHA("SHA-1", "TEXT");
      ShaObj.setHMACKey(AppKey, "TEXT");
      ShaObj.update("x-date: " + GMTString);
      var HMAC = ShaObj.getHMAC("B64");
      var Authorization =
        'hmac username="' +
        AppID +
        '", algorithm="hmac-sha1", headers="x-date", signature="' +
        HMAC +
        '"';

      return {
        Authorization: Authorization,
        "X-Date": GMTString /*,'Accept-Encoding': 'gzip'*/,
      };
    }

    // 取得到站時間data
    function updateArrivalData() {
      axios({
        method: "get",
        url:
          "https://ptx.transportdata.tw/MOTC/v2/Rail/Metro/LiveBoard/KRTC?$format=JSON",
        responseType: "json",
        headers: GetAuthorizationHeader(),
      }).then((res) => {
        mrtDatas.data = res.data;
      });
    }

    onMounted(() => {
      updateArrivalData();
    });

    return {
      updateArrivalData,
      mrtDatas,
      floatWrapWidth,
    };
  },
};
</script>

<template>
  <Clock />
  <div class="all">
    <div class="header">
      <div class="gl_wrap">
        <h1>MRT 高雄捷運</h1>
        <h2>所有到站時間</h2>
        <button class="btn-refresh" type="button" @click="updateArrivalData">
          更新<span>到站</span>時間
        </button>
      </div>
    </div>
    <div class="wrap">
      <div class="float-wrap" :style="{ width: floatWrapWidth }">
        <div
          class="card"
          v-for="eachData in mrtDatas.data"
          :key="eachData.StationID"
          :class="[
            eachData.LineName.Zh_tw === '紅線' ? 'card-red' : 'card-orange',
          ]"
        >
          <div class="card-main">
            <span class="destination-staion-id">{{ eachData.StationID }}</span>
          </div>
          <div
            class="card-sub flex-row flex-align-between"
            :class="[
              eachData.LineName.Zh_tw === '紅線'
                ? 'station-red'
                : 'station-orange',
            ]"
          >
            <div class="station-wrap">
              <strong class="trip-head-sign">{{
                eachData.TripHeadSign
              }}</strong>
              <div class="flex-row flex-align-center">
                <h3 class="station-name">{{ eachData.StationName.Zh_tw }}</h3>
              </div>
            </div>
            <div class="estimat-time">
              {{ eachData.EstimateTime }} <span>分</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


